<template>
  <p-loader v-if="loadingMenu" />
  <div
    v-else
    class="w-full overflow-auto"
  >
    <h1 class="">{{ $tk('Reports.LocationOverview', true) }}</h1>
    <p-loader v-if="loadingData" />
    <div v-else>
      <table class="mt-2 data-table">
        <thead>
          <tr>
            <th v-html="$tk('Reports.Name')"></th>
            <th v-html="$tk('Reports.Product')"></th>
            <th
              class="right"
              v-html="$tk('Reports.InboundVolume')"
            ></th>
            <th
              class="right"
              v-html="$tk('Reports.QuantityIn')"
            ></th>
            <th
              class="right"
              v-html="$tk('Reports.QuantityOut')"
            ></th>
            <th
              class="right"
              v-html="$tk('Reports.OutboundVolume')"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="inventory.balances.length > 1"
            class="gray"
          >
            <td
              class="font-bold"
              colspan="2"
              v-html="$tk('Reports.Sum')"
            />
            <td class="font-bold text-right">{{sumBy(inventory.balances, r => r.quantity)}}</td>
            <td class="font-bold text-right">{{sumBy(inventory.balances, r => r.quantityIn)}}</td>
            <td class="font-bold text-right">{{sumBy(inventory.balances, r => r.quantityOut)}}</td>
            <td class="font-bold text-right">{{sumBy(inventory.balances, r => r.quantityUpdated)}}</td>
          </tr>
          <tr
            v-for="(item, index) in inventory.balances"
            :key="index"
          >
            <td>{{ item.location }}</td>
            <td>{{ item.productName }}</td>
            <td class="text-right">{{ item.quantity }}</td>
            <td class="text-right">{{ item.quantityIn }}</td>
            <td class="text-right">{{ item.quantityOut }}</td>
            <td class="text-right">{{ item.quantityUpdated }}</td>
          </tr>
        </tbody>
      </table>
      <h1 class="mt-6">{{ $tk('Reports.Transactions') }}</h1>
      <table class="mt-2 data-table">
        <thead>
          <tr>
            <th v-html="$tk('Reports.ConfirmDate')"></th>
            <th v-html="$tk('Reports.From')"></th>
            <th v-html="$tk('Reports.To')"></th>
            <th v-html="$tk('Reports.Type')"></th>
            <th v-html="$tk('Reports.Product')"></th>
            <th
              class="right"
              v-html="$tk('Reports.QuantityIn')"
            ></th>
            <th
              class="right"
              v-html="$tk('Reports.QuantityOut')"
            ></th>
            <th v-html="$tk('Reports.OrderNumberSR')"></th>
            <th v-html="$tk('Reports.BestNumber')"></th>
            <th v-html="$tk('Reports.SerialNumber')"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="inventory.transactions.length > 0"
            class="gray"
          >
            <td
              class="font-bold"
              colspan="5"
              v-html="$tk('Reports.Sum')"
            ></td>
            <td class="font-bold text-right">{{ totalIncoming }}</td>
            <td class="font-bold text-right">{{ totalOutgoing }}</td>
            <td colspan="3"></td>
          </tr>
          <tr
            v-for="(transaction, index) in inventory.transactions"
            :key="index"
          >
            <td class="center">{{ transaction.dateConfirmed | date }}</td>
            <td>{{ transaction.addressFrom.name }}</td>
            <td>{{ transaction.addressTo.name }}</td>
            <td>{{ transaction.transactionTypeName }}</td>
            <td>{{ transaction.productName }}</td>
            <td class="text-right">{{ qtyIncoming(transaction) > 0 ? qtyIncoming(transaction) : '' }}</td>
            <td class="text-right">{{ qtyOutgoing(transaction) > 0 ? qtyOutgoing(transaction) : '' }}</td>
            <td>{{ transaction.orderId }}</td>
            <td>{{ transaction.directionLocation === 'In' ? transaction.receiversRef : transaction.sendersRef }}</td>
            <td>{{ transaction.directionLocation === 'Out' ? transaction.receiversRef : transaction.sendersRef }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <portal to="header">
      <div class="ml-1 text-xl font-medium text-gray-900">{{ $tk('Reports.BalanceControlPooling', true) }}</div>
    </portal>
    <portal to="filters">
      <div class="flex space-x-2">
        <PSelect
          v-model="selectedLocation"
          :items="locations"
          class="w-64"
          itemText="name"
          itemValue="id"
        />
        <PSelect
          v-model="selectedProduct"
          :items="poolingProducts"
          class="w-36"
          itemText="name"
          itemValue="id"
        />
        <PMonthPicker
          v-model="dateLower"
          :clearable="true"
          :emitFirstDayOfMonth="true"
          :maxValue="maxUpper"
          :placeholder="$t('Common.General.FromDate')"
          class="w-36"
        />
        <PMonthPicker
          v-model="dateUpper"
          :clearable="true"
          :emitLastDayOfMonth="true"
          :maxValue="maxUpper"
          :placeholder="$t('Common.General.ToDate')"
          class="w-36"
        />
      </div>
    </portal>
    <portal to="actions">
      <PButton
        :loading="isDownloading"
        color="secondary"
        icon="file-excel"
        @click="onDownload"
      >
        Excel
      </PButton>
    </portal>
  </div>
</template>

<script>
import { format, sub, startOfMonth, lastDayOfMonth, addDays } from "date-fns"
import { mapGetters } from 'vuex'
import { sumBy } from "lodash"
import fileDownload from 'js-file-download'
import http from "@/utilities/http"
import PLoader from "@/components/PLoader.vue"
import PMonthPicker from '@/components/forms/PMonthPicker.vue'

export default {
  name: "Pooling",
  components: {
    PLoader,
    PMonthPicker
  },
  data() {
    return {
      inventory: [],
      isDownloading: false,
      loadingMenu: true,
      loadingData: true,
      poolingProducts: [],
      locations: [],
      selectedLocation: undefined,
      dateUpper: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
      maxUpper: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
      dateLower: format(startOfMonth(sub(new Date(), { months: 3 })), "yyyy-MM-dd"),
      selectedProduct: undefined
    }
  },
  computed: {
    ...mapGetters(['customer', 'location']),
    queryState() {
      return `${this.dateLower}-${this.dateUpper}-${this.selectedProduct}-${this.selectedLocation}`
    },
    totalIncoming() {
      return sumBy(this.inventory.transactions, r => this.qtyIncoming(r))
    },
    totalOutgoing() {
      return sumBy(this.inventory.transactions, r => this.qtyOutgoing(r))
    },
    params() {
      return {
        confirmedLower: this.dateLower,
        confirmedUpper: this.dateUpper,
        flat: true,
        isReport: true,
        locationId: this.selectedLocation,
        productId: this.selectedProduct,
      }
    }
  },
  methods: {
    sumBy,
    qtyIncoming(transaction) {
      if (this.selectedLocation.includes(',')) { // All
        return transaction.quantityIn
      } if (this.selectedLocation === transaction.locationIdTo) {
        return transaction.quantityIn
      }
      return 0
    },
    qtyOutgoing(transaction) {
      if (this.selectedLocation.includes(',')) { // All
        return transaction.quantityOut
      } if (this.selectedLocation === transaction.locationIdFrom) {
        return transaction.quantityOut
      }
      return 0
    },
    async getInventory() {
      const t = this.dateUpper.split('-')
      const response = await http.get("InventoryPoolingPeriod", {
        params: {
          locationId: this.selectedLocation,
          productId: this.selectedProduct,
          dateUpper: format(addDays(new Date(t[0], (t[1] - 1), t[2]), 1), "yyyy-MM-dd"), // The api expects, start of month instead of end of month,
          dateLower: this.dateLower
        }
      })
      return response
    },
    async onDownload() {
      this.isDownloading = true
      const t = this.dateUpper.split('-')
      const inventoryData = await http.get("InventoryPoolingPeriod", {
        params: {
          locationId: this.selectedLocation,
          productId: this.selectedProduct,
          dateUpper: format(addDays(new Date(t[0], (t[1] - 1), t[2]), 1), "yyyy-MM-dd"), // The api expects, start of month instead of end of month,
          dateLower: this.dateLower,
          excel: "download"
        }, responseType: "blob"
      })
      const prefix = format(new Date(), "yyyy-MM-dd")
      fileDownload(inventoryData, `${prefix}_inventoryPoolingPeriod.xlsx`)
      this.isDownloading = false
    }
  },
  async created() {
    this.loadingMenu = true
    this.poolingProducts = await http.get("Products", { params: { isPooling: true } })
    this.selectedProduct = this.poolingProducts[0].id
    this.locations = await http.get("Locations", { params: { customerId: this.customer.id } })
    this.locations.unshift({ id: this.locations.map(r => r.id).toString(), name: this.$t('Common.General.All') })
    this.selectedLocation = this.location.id
    this.loadingMenu = false
  },
  watch: {
    queryState: {
      async handler() {
        if (!this.selectedLocation) {
          return
        }
        this.loadingData = true
        this.inventory = await this.getInventory()

        if (this.dateLower > this.dateUpper) {
          this.dateLower = this.dateUpper
        }

        this.loadingData = false
      },
      immediate: false
    }
  }

}
</script>

<style scoped>
tbody tr.gray {
  @apply bg-gray-200
}
</style>